
































































import { UserInfo, cuBid, updateBid } from '@/api/bid';
import { ValidationRule } from 'ant-design-vue/types/form/form';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

function validMobile(
  rule: ValidationRule,
  value: string,
  callback: (message?: Error) => void
): void {
  if (!value) {
    return callback(new Error('请输入联系电话'));
  }
  if (!/^1\d{10}$/.test(value)) {
    return callback(new Error('联系电话格式有误'));
  }
  return callback();
}

@Component
export default class BidForm extends Vue {
  @Prop() visible!: boolean;
  @Prop() type!: string;
  @Prop() record!: UserInfo;

  formState = {
    userName: '',
    userMobile: '',
    remark: '',
    projectName: '',
    companyName: '',
    downPaymentAmount: '',
    tailPaymentAmount: '',
    introducer: '',
    sequenceNbr: '',
    businessManager: '',
  };
  rules = {
    userName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
    userMobile: [{ required: true, validator: validMobile, trigger: 'blur' }],
    projectName: [
      { required: true, message: '请输入项目名称', trigger: 'blur' },
    ],
    companyName: [
      { required: true, message: '请输入企业名称', trigger: 'blur' },
    ],
    downPaymentAmount: [
      { required: true, message: '请输入首付款', trigger: 'blur' },
    ],
    tailPaymentAmount: [
      { required: true, message: '请输入尾款', trigger: 'blur' },
    ],
    businessManager: [
      { required: true, message: '请输入商务负责人', trigger: 'blur' },
    ],
  };

  @Watch('record', { immediate: true })
  watchRecord(val: UserInfo): void {
    this.formState = {
      ...this.formState,
      ...val,
    };
  }

  closeModal(): void {
    (this.$refs['form'] as HTMLFormElement).resetFields();
    this.$emit('update:visible', false);
  }

  submitForm(form: string): void {
    (this.$refs[form] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        return false;
      }
      const { downPaymentAmount, tailPaymentAmount } = this.formState;
      const api = this.type === 'create' ? cuBid : updateBid;
      api({
        ...this.formState,
        downPaymentAmount: +downPaymentAmount,
        tailPaymentAmount: +tailPaymentAmount,
        orderAmount: +downPaymentAmount + +tailPaymentAmount,
        description: '标书制作费',
      }).then(() => {
        this.$message.success('操作成功');
        this.$emit('refresh');
      });
      this.closeModal();
    });
  }
}
