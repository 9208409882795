/*
 * @Author: zhangguoliang
 * @Date: 2022-08-05 10:25:41
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2022-09-15 13:54:48
 */
import request from '@/utils/request';
import { BaseResponse } from '@/interface';

export type createBid = {
  userName: string;
  userMobile: string;
  remark?: string;
  projectName: string;
  companyName: string;
  orderAmount?: number;
  downPaymentAmount: number;
  tailPaymentAmount: number;
  introducer?: string;
  businessManager: string;
  description?: string;
};

export type UserInfo = Pick<
  bidItem,
  'userName' | 'userMobile' | 'remark' | 'sequenceNbr'
>;

export type bidItem = createBid & {
  status: string;
  sequenceNbr: string;
  orderAmount: number;
  createTime: string;
  downPaymentTime: string;
  tailPaymentTime: string;
};

export type PageQuery = Pick<bidItem, 'userMobile' | 'userName' | 'status'> & {
  createTime: string[];
  downPaymentTime: string[];
  tailPaymentTime: string[];
};

export type Params = Pick<bidItem, 'userMobile' | 'userName' | 'status'> & {
  page: number;
  limit: number;
  createStartTime: string;
  createEndTime: string;
  downPaymentStartTime: string;
  downPaymentEndTime: string;
  tailPaymentStartTime: string;
  tailPaymentEndTime: string;
};

type PageRes = {
  total: number;
  list: bidItem[];
};

// 列表查询
export function getBid(params: Params): Promise<BaseResponse<PageRes>> {
  return request({
    url: `/order-resource/1/preOrderInfo`,
    method: 'GET',
    params,
  });
}

export function rejectBid(id: string): Promise<BaseResponse> {
  return request({
    url: `/order-resource/1/preOrderInfo/reject/${id}`,
    method: 'GET',
  });
}

export function cuBid(data: createBid): Promise<BaseResponse> {
  return request({
    url: `/order-resource/1/preOrderInfo/pc`,
    method: 'POST',
    data,
  });
}

export function bidPay(
  orderNo: string
): Promise<BaseResponse<{ qrCodeUrl: string }>> {
  return request({
    url: `/order-resource/1/order/v2/${orderNo}/1/wx_pub_qr/1/pay/PREPAID`,
    method: 'GET',
  });
}

export function updateBid(
  data: createBid & { sequenceNbr: string }
): Promise<BaseResponse> {
  return request({
    url: `/order-resource/1/preOrderInfo`,
    method: 'PUT',
    data,
  });
}

export function bidDetail(id: string): Promise<BaseResponse<bidItem>> {
  return request({
    url: `/order-resource/1/preOrderInfo/${id}`,
    method: 'GET',
  });
}

export function exportBid(params: Params): Promise<ArrayBuffer> {
  return request({
    url: `/order-resource/1/preOrderInfo/exportExcel`,
    method: 'GET',
    params,
    responseType: 'blob',
  });
}
